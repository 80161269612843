import {
  ChainId,
  CHAIN_ID_BSC,
  CHAIN_ID_ETH,
  CHAIN_ID_POLYGON,
  CHAIN_ID_SAFECOIN,
  CHAIN_ID_SOLANA,
  CHAIN_ID_TERRA,
} from "@certusone/wormhole-sdk";
import { Button, createTheme, makeStyles, Theme, ThemeProvider, Typography, withStyles } from "@material-ui/core";
import { green } from "@material-ui/core/colors";
import { Alert } from "@material-ui/lab";
import { Transaction } from "../store/transferSlice";
import { CLUSTER, getExplorerName } from "../utils/consts";
import etherscan from "../images/etherscan.svg"
import safecoinssmall from "../icons/safecoinssmall.svg"
import { isMobile } from "react-device-detect";
const theme = createTheme({
  palette: {
    primary: green,
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: "5px",
        textTransform: "none",
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  tx: {
    marginTop: theme.spacing(2),
    textAlign: "center",
  },
  viewButton: {
    //background: "red",
    marginTop: "8px",
    // marginTop: theme.spacing(1),
  },
}));

export default function ShowTx({
  chainId,
  tx,
}: {
  chainId: ChainId;
  tx: Transaction;
}) {
  const classes = useStyles();
  const showExplorerLink =
    CLUSTER === "testnet" ||
    CLUSTER === "mainnet" ||
    (CLUSTER === "devnet" &&
      (chainId === CHAIN_ID_SOLANA || chainId === CHAIN_ID_TERRA));
  const explorerAddress =
    chainId === CHAIN_ID_ETH
      ? `https://${CLUSTER === "testnet" ? "goerli." : ""}etherscan.io/tx/${tx?.id
      }`
      : chainId === CHAIN_ID_BSC
        ? `https://bscscan.com/tx/${tx?.id}`
        : chainId === CHAIN_ID_POLYGON
          ? `https://polygonscan.com/tx/${tx?.id}`
          : chainId === CHAIN_ID_SAFECOIN
            ? `https://explorer.safecoin.org/tx/${tx?.id}${CLUSTER === "testnet"
              ? "?cluster=testnet"
              : CLUSTER === "devnet"
                ? "?cluster=custom&customUrl=http%3A%2F%2Flocalhost%3A8328"
                : ""
            }`
            : chainId === CHAIN_ID_SOLANA
              ? `https://explorer.solana.com/tx/${tx?.id}${CLUSTER === "testnet"
                ? "?cluster=testnet"
                : CLUSTER === "devnet"
                  ? "?cluster=custom&customUrl=http%3A%2F%2Flocalhost%3A8899"
                  : ""
              }`
              : chainId === CHAIN_ID_TERRA
                ? `https://finder.terra.money/${CLUSTER === "devnet"
                  ? "localterra"
                  : CLUSTER === "testnet"
                    ? "bombay-12"
                    : "columbus-5"
                }/tx/${tx?.id}`
                : undefined;
  const explorerName = getExplorerName(chainId);

  function explIcon() {
    if (chainId === 2) {
      return (
        <img style={{ width: "22px", height: "auto" }} src={etherscan}></img>
      )
    } else {
      return (
      <img style={{ width: "22px", height: "auto" }} src={safecoinssmall}></img>
      )
    }
  }

  return (
    <div className={classes.tx} style={isMobile ? {} : { display: 'flex', justifyContent: "space-around" }}>
      <Alert severity="success">
        <div style={{ fontSize: "12px" }}>{tx.id}</div>
      </Alert>
      {showExplorerLink && explorerAddress ? (
        <ThemeProvider theme={theme}>
          <Button
            href={explorerAddress}
            target="_blank"
            rel="noopener noreferrer"
            size="small"
            variant="text"
            className={classes.viewButton}
            startIcon={explIcon()}
          >
            View on {explorerName}
          </Button>
        </ThemeProvider>
      ) : null}

    </div>
  );
}
